import React, { useState, useEffect } from 'react';
import { firebase, storage } from '../db/Firebase';
import AdminFrame from '../components/hoc/AdminFrame';
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

const Achievements = () => {
    const [entries, setEntries] = useState([]);
    const [file, setFile] = useState(null);
    const [text, setText] = useState("");
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    useEffect(() => {
        const fetchEntries = async () => {
            const dbRef = firebase.database().ref('achievements');
            dbRef.on('value', (snapshot) => {
                const entriesData = snapshot.val();
                const loadedEntries = [];
                for (let id in entriesData) {
                    loadedEntries.push({ id, ...entriesData[id] });
                }
                setEntries(loadedEntries.sort((a, b) => b.createdAt - a.createdAt));
            });
        };
        fetchEntries();
    }, []);

    const handleChangeFile = (e) => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
            setUploadSuccess(false);
        }
    };

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    const uploadImage = () => {
        if (!file) {
            console.log("No file selected.");
            return;
        }
        setUploading(true);
        const uniqueName = `${new Date().getTime()}-${file.name}`;
        const storageRef = storage.ref(`images/${uniqueName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
            },
            (error) => {
                console.error(error);
                setUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    const dbRef = firebase.database().ref('achievements');
                    dbRef.push({
                        type: 'image',
                        content: downloadURL,
                        createdAt: new Date().getTime(),
                    });
                    setUploading(false);
                    setUploadSuccess(true);
                    setFile(null);
                    setTimeout(() => setUploadSuccess(false), 3000);
                });
            }
        );
    };

    const uploadText = () => {
        if (!text) {
            console.log("No text entered.");
            return;
        }
        setUploading(true);
        const dbRef = firebase.database().ref('achievements');
        dbRef.push({
            type: 'text',
            content: text,
            createdAt: new Date().getTime(),
        }, () => {
            setUploading(false);
            setUploadSuccess(true);
            setText('');
            setTimeout(() => setUploadSuccess(false), 3000);
        });
    };
    const deleteEntry = (id) => {
        const entryToDelete = entries.find(entry => entry.id === id);
        if (!entryToDelete) {
            console.error("Entry not found");
            return;
        }
        
        if (entryToDelete.type === 'image') {
            if (!entryToDelete.content) {
                console.error("Image entry does not have content");
                return;
            }
    
            try {
                const url = new URL(entryToDelete.content);
                let imageName = url.pathname.split('/').pop(); 
    
                imageName = decodeURIComponent(imageName);
    
                if (!imageName) {
                    console.error("Could not extract image name from URL");
                    return;
                }
    
                // Direct reference to the storage object without assuming additional path segments
                const imageRef = storage.ref(imageName); // Adjusted to use the decoded imageName directly
    
                imageRef.delete().then(() => {
                    const dbRef = firebase.database().ref('achievements').child(id);
                    dbRef.remove().then(() => {
                        console.log('Entry deleted successfully');
                    }).catch((error) => {
                        console.error("Error deleting entry from database:", error);
                    });
                }).catch((error) => {
                    console.error("Error deleting image from storage:", error);
                });
            } catch (error) {
                console.error("Error parsing URL:", error);
            }
        } else {
            const dbRef = firebase.database().ref('achievements').child(id);
            dbRef.remove().then(() => {
                console.log('Text entry deleted successfully');
            }).catch((error) => {
                console.error("Error deleting text entry:", error);
            });
        }
    };
    
    return (
        <AdminFrame>
            <div className="p-4">
                <input
                    type="text"
                    value={text}
                    onChange={handleTextChange}
                    placeholder="Enter text"
                    className="mb-4 w-full p-2 border rounded"
                />
                <button
                    onClick={uploadText}
                    disabled={uploading || !text.trim()}
                    className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Upload Text
                </button>
                <br/>
                <input
                    type="file"
                    onChange={handleChangeFile}
                    disabled={uploading}
                    className="mb-4 file:border file:border-blue-500 file:px-4 file:py-2 file:rounded file:text-white file:bg-blue-500 file:cursor-pointer"
                />
                <button
                    onClick={uploadImage}
                    disabled={uploading || !file}
                    className="mb-4 ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Upload Image
                </button>
                {uploading && <p>Uploading...</p>}
                {uploadSuccess && <p className="text-green-500 animate-pulse">Uploaded successfully!</p>}
                <div className="mt-8 overflow-hidden border border-gray-200">
                    <table className="min-w-full leading-normal">
                        <thead>
                            <tr className="border-b">
                                <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    S.No.
                                </th>
                                <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    Content
                                </th>
                                <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {entries.map((entry, index) => (
                                <tr key={entry.id} className="border-b">
                                    <td className="px-5 py-5 border-r text-sm">
                                        {index + 1}
                                    </td>
                                    <td className="px-5 py-5 border-r text-sm">
                                        {entry.type === 'text' ? entry.content : <img src={entry.content} alt="Uploaded" className="w-full h-auto" style={{ maxWidth: '200px' }} />}
                                    </td>
                                    <td className="px-5 py-5 text-sm">
                                        <button onClick={() => deleteEntry(entry.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </AdminFrame>
    );
};

export default Achievements;
