import React, { useEffect, useState } from "react";
import AdminFrame from "../components/hoc/AdminFrame";
import {firebase} from "../db/Firebase"

const Magazine = () => {
        const [magazine, setMagazine] = useState([]);

        useEffect(() => {
            const magazineRef = firebase.database().ref('magazine');
            magazineRef.on('value', (snapshot) => {
              const magazine = snapshot.val();
              const magazineList = [];
              for (let id in magazine) {
                magazineList.push({ id, ...magazine[id] });
              }
              setMagazine(magazineList);
            });
          }, []);

const handleSubmit = async (e) => {
    e.preventDefault();
    const title = e.target.elements.title.value;
    const fileInput = e.target.elements.file;
    const file = fileInput.files[0];
  
    let pdfUrl = null;
    try {
      if (file) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`pdfs/${file.name}`);
        await fileRef.put(file);
        pdfUrl = await fileRef.getDownloadURL();
      }
  
      const newMagazineRef = firebase.database().ref('magazine').push();
      await newMagazineRef.set({
        title,
        pdf: pdfUrl,
      });

      e.target.elements.title.value = '';
      e.target.elements.file.value = '';
 
      alert('Magazine added successfully!');
    } catch (error) {
      console.error("Error adding magazine: ", error);
      alert('Failed to add magazine.');
    }
  };
  
  const handleDeleteMagazine = async (magazineId, pdfUrl) => {
    try {

      await firebase.database().ref(`magazine/${magazineId}`).remove(); 

      if (pdfUrl) {
        const fileRef = firebase.storage().refFromURL(pdfUrl);
        await fileRef.delete();
      }
      alert('magazine deleted successfully!');
    } catch (error) {
      console.error("Error deleting magazine: ", error);
      alert('Failed to delete magazine, the file might have already been removed.');
    }
  };
  

  return (
    <AdminFrame>
      <div className="p-5">
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <input
              type="text"
              name="title"
              placeholder="Enter notice details"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                Upload PDF
              </label>
              <input
                type="file"
                name="file"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                accept="application/pdf"
              />
            </div>
          </div>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add Magazine
          </button>
        </form>

        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Magazine
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  PDF
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {magazine.map((circular, index) => (
                <tr key={index}>
               
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {circular.title}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {circular.pdf ? <a href={circular.pdf} target="_blank" rel="noopener noreferrer">PDF Attached</a> : "No PDF"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button onClick={() => handleDeleteMagazine(circular.id, circular.pdf)} className="text-red-600 hover:text-red-900">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AdminFrame>
  );
};

export default Magazine;