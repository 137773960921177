import React, { useEffect, useState } from "react";
import AdminFrame from "../components/hoc/AdminFrame";
import { firebase, storage } from "../db/Firebase"; // Ensure you have imported storage correctly
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const UpdateEvent = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const eventsRef = firebase.database().ref("events");
    eventsRef.on("value", (snapshot) => {
      const events = snapshot.val();
      const eventsList = [];
      for (let id in events) {
        eventsList.push({ id, ...events[id] });
      }
      eventsList.sort((a, b) => b.createdAt - a.createdAt);
    setEvents(eventsList);
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const date = form.elements.date.value;
    const title = form.elements.title.value;
    const description = form.elements.description.value;
    const files = form.elements.images.files;

    let imageUrls = [];
    if (files.length) {
      const uploadPromises = Array.from(files).map((file) => {
        const fileRef = storageRef(storage, `events/images/${Date.now()}-${file.name}`);
        return uploadBytesResumable(fileRef, file).then(() => getDownloadURL(fileRef));
      });

      try {
        imageUrls = await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Error uploading images: ", error);
        alert('Failed to upload images.');
        return;
      }
    }

    try {
      const newEventRef = firebase.database().ref("events").push();
      await newEventRef.set({
        date,
        title,
        description,
        images: imageUrls,
        createdAt: new Date().getTime(),
      });
      alert('Event uploaded successfully!');
      form.reset(); // Reset the form fields
    } catch (error) {
      console.error("Error saving event: ", error);
      alert('Failed to save the event.');
    }
  };

  const handleDeleteEvent = async (eventId, imageUrls) => {
    // Remove the event entry from the database
    await firebase.database().ref(`events/${eventId}`).remove();

    // Delete associated images from storage
    if (imageUrls && imageUrls.length) {
        const deletePromises = imageUrls.map((imageUrl) => {
            // Use refFromURL with the full download URL
            const fileRef = storage.refFromURL(imageUrl);
            return fileRef.delete();
        });

        try {
            await Promise.all(deletePromises);
            alert('Event and associated images deleted successfully.');
        } catch (error) {
            console.error("Error deleting images: ", error);
            alert('Failed to delete some or all images.');
        }
    }
};

  return (
    <AdminFrame>
      <div className="p-5">
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            {/* First row with Date and Title */}
            <input
              type="date"
              name="date"
              placeholder="Event Date"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <input
              type="text"
              name="title"
              placeholder="Enter event title"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />

            {/* Second row with Description and File Upload */}
            <input
              type="text"
              name="description"
              placeholder="Enter event description"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <div className="flex flex-col">
              <label className="block text-sm font-medium text-gray-700">
                Upload Images (Optional)
              </label>
              <input
                type="file"
                name="images"
                multiple // Allow multiple file selection
                accept="image/*" // Accept all image types
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add Event
          </button>
        </form>

        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Events
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Description
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Images
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {events.map((event, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {event.date}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {event.title}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {event.description}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {event.images && event.images.length > 0 ? (
                      <img
                        src={event.images[0]}
                        alt="event"
                        style={{ width: "100px", height: "auto" }}
                      />
                    ) : (
                      "No Images"
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => handleDeleteEvent(event.id, event.images)}
                      className="text-red-600 hover:text-red-900"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AdminFrame>
  );
};

export default UpdateEvent;
