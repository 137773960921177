import React, { useEffect } from "react";

import { useState } from "react";
import { firebase } from "../db/Firebase";
import AdminFrame from "../components/hoc/AdminFrame";

export default function Home() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        
        setUser(authUser);
      } else {
       
      }
    });

    
    return () => unsubscribe();
  }, []);


  return (
    <AdminFrame>
      <div className="pt-56 text-5xl bg-cover bg-center h-screen"  style={{
      backgroundImage: `url('https://getwallpapers.com/wallpaper/full/0/5/2/129199.jpg')`,
    }}>
        {user ? (
          <div className="text-white grid justify-center items-center">
            <h1 className="font-semibold">
              Hello,{" "}
              <span className="  text-orange-400 font-extrabold text-4xl">
                {" "}
                {user.displayName} {user.email}
              </span>
            </h1>
            <span className=" font-bold flex justify-center mt-4">
              {" "}
              How are you?
            </span>{" "}
            {/* Your protected content */}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </AdminFrame>
  );
}
