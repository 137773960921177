import React, { useEffect, useState } from "react";
import AdminFrame from "../components/hoc/AdminFrame";
import { firebase } from "../db/Firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const PublicDisclosure = () => {
  const [publicDisclosure, setPublicDisclosure] = useState([]);
  const [contentType, setContentType] = useState("text");

  useEffect(() => {
    const publicDisclosureRef = firebase.database().ref('publicDisclosure');
    publicDisclosureRef.on('value', (snapshot) => {
      const disclosures = snapshot.val();
      const loadedDisclosures = [];
      for (let id in disclosures) {
        loadedDisclosures.push({ id, ...disclosures[id] });
      }
      setPublicDisclosure(loadedDisclosures);
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const title = e.target.elements.title.value;
    let content;
    let type = contentType;

    if (contentType === "pdf") {
      const fileInput = e.target.elements.file;
      const file = fileInput.files[0];
      if (file) {
        const storage = getStorage(); // Get a reference to the storage service
        const storageRef = ref(storage, `pdfs/${file.name}`);
        try {
          const snapshot = await uploadBytes(storageRef, file);
          content = await getDownloadURL(snapshot.ref);
          // Now content holds the URL for the uploaded PDF

          const newPublicDisclosureRef = firebase.database().ref('publicDisclosure').push();
          await newPublicDisclosureRef.set({ title, type, content });
          setContentType("text"); // Reset form fields and content type
          alert('Public Disclosure added successfully!');
        } catch (error) {
          console.error("Error uploading file: ", error);
          alert('Failed to upload PDF.');
        }
      }
    } else if (contentType === "text") {
      content = e.target.elements.textContent.value;
      // Your logic for handling "text" content type here
    } else if (contentType === "link") {
      content = e.target.elements.link.value;
      // Your logic for handling "link" content type here
    }

    // If contentType is not "pdf", you would still need to handle adding the public disclosure
    // Similar to the try block inside the if (file) { ... } condition but for text and link types
  };


  const handleDeletePublicDisclosure = async (id, type, content) => {
    try {
      await firebase.database().ref(`publicDisclosure/${id}`).remove();
      if (type === 'pdf') {
        const fileRef = firebase.storage().refFromURL(content);
        await fileRef.delete();
      }
      alert('Public Disclosure deleted successfully!');
    } catch (error) {
      console.error("Error deleting public disclosure: ", error);
      alert('Failed to delete public disclosure.');
    }
  };

  return (
    <AdminFrame>
      <div className="p-5">
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="grid grid-cols-1 gap-4 mb-4">
            <input
              type="text"
              name="title"
              placeholder="Enter title"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <select
              name="type"
              value={contentType}
              onChange={(e) => setContentType(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="text">Text</option>
              <option value="pdf">PDF</option>
              <option value="link">Link</option>
            </select>
            {contentType === "text" && (
              <textarea
                name="textContent"
                placeholder="Enter text content"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            )}
            {contentType === "pdf" && (
              <input
                type="file"
                name="file"
                accept="application/pdf"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            )}
            {contentType === "link" && (
              <input
                type="text"
                name="link"
                placeholder="Enter link"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            )}
          </div>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add Public Disclosure
          </button>
        </form>

        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Title
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Content
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {publicDisclosure.map((item) => (
                <tr key={item.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.title}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item.type === 'text' ? item.content :
                     item.type === 'pdf' ? <a href={item.content} target="_blank" rel="noopener noreferrer">PDF</a> :
                     <a href={item.content} target="_blank" rel="noopener noreferrer">Link</a>}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button onClick={() => handleDeletePublicDisclosure(item.id, item.type, item.content)} className="text-red-600 hover:text-red-900">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AdminFrame>
  );
};

export default PublicDisclosure;





