import './App.css';
import Home from './admin/Home';
import SignIn from './admin/SignIn';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Enquiry from './admin/Enquiry';
import Registration from './admin/Registration';
import ImageSlider from './admin/ImageSlidder';
import Gallery from './admin/Gallery';
import Explore from './admin/Explore';
import UpdateEvent from './admin/UpdateEvent';
import Circular from './admin/Circular';
import PublicDisclosure from './admin/PublicDisclosure';
import Magazine from './admin/Magazine';
import Achievements from './admin/Achievements';
import Toppers from './admin/Toppers';
import Calendar from './admin/Calendar';
import CampusTour from './admin/CampusTour';
import Fee from './admin/Fee';
import CalenderWithEvent from './admin/CalenderWithEvent';
import HomeModal from './admin/HomeModal';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/school/achievements" element={<Achievements />} />
        <Route path="/students/Update-calender-event" element={<Calendar />} />
        <Route path="/students/all-calender" element={<CalenderWithEvent />} />
        <Route path="/students/Update-campus-tour" element={<CampusTour />} />
        <Route path="/students/Update-circular" element={<Circular />} />
        <Route path="/students/enquiry" element={<Enquiry />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/fee" element={<Fee />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/popup" element={<HomeModal />} />
        <Route path="/image-slidder" element={<ImageSlider />} />
        <Route path="/students/Update-magazine" element={<Magazine />} />
        <Route path="/students/public-disclosure" element={<PublicDisclosure />} />
        <Route path="/students/registration" element={<Registration />} />
        <Route path="/students/Update-event" element={<UpdateEvent />} />
        <Route path="/school/toppers" element={<Toppers />} />
      </Routes> 
    </Router>
  );
}

export default App;
