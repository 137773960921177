import React, { useState, useEffect, useCallback } from "react";
import { firebase } from "../db/Firebase";
import AdminFrame from "../components/hoc/AdminFrame";

const Enquiry = () => {
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [editedData, setEditedData] = useState({});
  const [error, setError] = useState(null);

  const fetchDataFromFirebase = useCallback(() => {
    try {
      const queryFormsRef = firebase.database().ref("contactFormData");

      queryFormsRef.on("value", (snapshot) => {
        const queryFormData = snapshot.val();
        if (queryFormData) {
          const dataArray = Object.values(queryFormData);
          setData(dataArray);
          setError(null);
        } else {
          setData([]);
        }
      });
    } catch (error) {
      setError("Error fetching data: " + error.message);
    }
  }, []);

  useEffect(() => {
    fetchDataFromFirebase();
  }, [fetchDataFromFirebase]);

  return (
    <AdminFrame>
      <div className="w-full">
        <section className="bg-blueGray-50">
          <div className="h-16 sticky top-0 bg-emerald-950">
            <h2 className="text-white flex justify-center pt-3 text-3xl font-bold">
              Enquiry Details
            </h2>
          </div>
          <table className="items-center min-w-full">
            <thead className="sticky top-[60px] bg-slate-200 ">
              <tr className="w-full">
                <th className="w-1/12 px-5 py-2 text-[16px] uppercase font-semibold text-left " style={{ border: "1px solid gray" }}>
                  SrNo.
                </th>
                <th className=" w-1/6 px-5 py-2 text-[16px] uppercase font-semibold text-left " style={{ border: "1px solid gray" }}>
                  Name
                </th>
                <th className=" w-1/5/2  px-5 py-2 text-[16px] uppercase font-semibold text-left " style={{ border: "1px solid gray" }}>
                  Email
                </th>
                <th className="w-1/12 px-5 py-2 text-[16px] uppercase font-semibold text-left " style={{ border: "1px solid gray" }}>
                  Mobile No
                </th>
                <th className="w-2/12 px-5 py-2 text-[16px] uppercase font-semibold text-left " style={{ border: "1px solid gray" }}>
                  Enquiry
                </th>
                <th className="w-5/12 px-5 py-2 text-[16px] uppercase font-semibold text-left " style={{ border: "1px solid gray" }}>
                  Message
                </th>
              </tr>
            </thead>
            <tbody style={{ border: "1px solid gray" }}>
              {data.map((contactFormData, index) => (
                <tr key={contactFormData.id} style={{ border: "1px solid gray" }}>
                  <td className="px-8 text-black align-middle text-[16px]">
                    {index + 1}.
                  </td>
                  <td className="text-black text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.name}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            name: e.target.value,
                          })
                        }
                      />
                    ) : (
                      contactFormData.name
                    )}
                  </td>
                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.email}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            email: e.target.value,
                          })
                        }
                      />
                    ) : (
                      contactFormData.email
                    )}
                  </td>
                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.mobile}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            mobileNo: e.target.value,
                          })
                        }
                      />
                    ) : (
                      contactFormData.mobile
                    )}
                  </td>
                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.option}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            courseToApply: e.target.value,
                          })
                        }
                      />
                    ) : (
                      contactFormData.option
                    )}
                  </td>
                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.message}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            message: e.target.value,
                          })
                        }
                      />
                    ) : (
                      contactFormData.message
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </div>
    </AdminFrame>
  );
};

export default Enquiry;
