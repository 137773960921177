// import React, { useEffect, useState } from "react";
// import AdminFrame from "../components/hoc/AdminFrame";
// import { firebase } from "../db/Firebase";

// const Toppers = () => {
//   const [entries, setEntries] = useState([]);

//   useEffect(() => {
//     const entriesRef = firebase.database().ref('toppers');
//     entriesRef.on('value', (snapshot) => {
//       const entries = snapshot.val();
//       const loadedEntries = [];
//       for (let id in entries) {
//         loadedEntries.push({ id, ...entries[id] });
//       }
//       setEntries(loadedEntries);
//     });
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const { title, file, classType, image } = e.target.elements;
//     const pdfFile = file.files[0];
//     const imageFile = image.files[0];

//     let pdfUrl = null, imageUrl = null;
//     const storageRef = firebase.storage().ref();

//     try {
//       // Upload PDF
//       if (pdfFile) {
//         const pdfRef = storageRef.child(`pdfs/${pdfFile.name}`);
//         await pdfRef.put(pdfFile);
//         pdfUrl = await pdfRef.getDownloadURL();
//       }

//       // Upload Image
//       if (imageFile) {
//         const imageRef = storageRef.child(`images/${imageFile.name}`);
//         await imageRef.put(imageFile);
//         imageUrl = await imageRef.getDownloadURL();
//       }

//       // Save entry
//       const newEntryRef = firebase.database().ref('toppers').push();
//       await newEntryRef.set({
//         title: title.value,
//         classType: classType.value,
//         pdf: pdfUrl,
//         image: imageUrl,
//       });

//       title.value = '';
//       classType.value = 'classX'; // or classXII, depending on your default
//       file.value = '';
//       image.value = '';

//       alert('Entry added successfully!');
//     } catch (error) {
//       console.error("Error adding entry: ", error);
//       alert('Failed to add entry.');
//     }
//   };

//   const handleDeleteEntry = async (entryId, pdfUrl, imageUrl) => {
//     try {
//       // Delete from database
//       await firebase.database().ref(`toppers/${entryId}`).remove(); 

//       // Delete PDF
//       if (pdfUrl) {
//         const pdfRef = firebase.storage().refFromURL(pdfUrl);
//         await pdfRef.delete();
//       }

//       // Delete Image
//       if (imageUrl) {
//         const imageRef = firebase.storage().refFromURL(imageUrl);
//         await imageRef.delete();
//       }

//       alert('Entry deleted successfully!');
//     } catch (error) {
//       console.error("Error deleting entry: ", error);
//       alert('Failed to delete entry.');
//     }
//   };

//   return (
//     <AdminFrame>
//       <div className="p-5">
//         <form onSubmit={handleSubmit} className="mb-6">
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
//             <input type="text" name="title" placeholder="Enter title" required className="input"/>
//             <select name="classType" className="select">
//               <option value="classX">Class X</option>
//               <option value="classXII">Class XII</option>
//             </select>
//             <input type="file" name="image" accept="image/*" required className="file-input"/>
//             <input type="file" name="file" accept="application/pdf" required className="file-input"/>
//           </div>
//           <button type="submit" className="submit-btn">Add Entry</button>
//         </form>

//         {/* <div className="entries-table">
//           {entries.map((entry) => (
//             <div key={entry.id} className="entry-row">
//               <span>{entry.title}</span>
//               <span>{entry.classType}</span>
//               <a href={entry.pdf} target="_blank" rel="noopener noreferrer">View PDF</a>
//               <button onClick={() => handleDeleteEntry(entry.id, entry.pdf, entry.image)}>Delete</button>
//             </div>
//           ))}
//         </div> */}
//         <table className="min-w-full divide-y divide-gray-200 mt-6">
//           <thead className="bg-gray-50">
//             <tr>
//               <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                 Title
//               </th>
//               <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                 Class Type
//               </th>
//               <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                 PDF
//               </th>
//               <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                 Actions
//               </th>
//             </tr>
//           </thead>
//           <tbody className="bg-white divide-y divide-gray-200">
//             {entries.map((entry) => (
//               <tr key={entry.id}>
//                 <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                   {entry.title}
//                 </td>
//                 <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                   {entry.classType}
//                 </td>
//                 <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                   <a href={entry.pdf} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-900">
//                     View PDF
//                   </a>
//                 </td>
//                 <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
//                   <button onClick={() => handleDeleteEntry(entry.id, entry.pdf, entry.image)} className="text-red-600 hover:text-red-900">
//                     Delete
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </AdminFrame>
//   );
// };

// export default Toppers;



import React, { useEffect, useState } from "react";
import AdminFrame from "../components/hoc/AdminFrame";
import { firebase } from "../db/Firebase";

const Toppers = () => {
    const [entries, setEntries] = useState([]);
    const [uploading, setUploading] = useState(false); // Use this state to track uploading status
  
    useEffect(() => {
      const entriesRef = firebase.database().ref('toppers');
      entriesRef.on('value', (snapshot) => {
        const entries = snapshot.val();
        const loadedEntries = [];
        for (let id in entries) {
          loadedEntries.push({ id, ...entries[id] });
        }
        setEntries(loadedEntries);
      });
    }, []);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setUploading(true); // Set uploading to true to indicate the start of upload
      const { title, file, classType, image } = e.target.elements;
      const pdfFile = file.files[0];
      const imageFile = image.files.length > 0 ? image.files[0] : null;
  
      let pdfUrl = null, imageUrl = null;
      const storageRef = firebase.storage().ref();
  
      try {
        if (pdfFile) {
          const pdfRef = storageRef.child(`pdfs/${pdfFile.name}`);
          await pdfRef.put(pdfFile);
          pdfUrl = await pdfRef.getDownloadURL();
        }
  
        if (imageFile) {
          const imageRef = storageRef.child(`images/${imageFile.name}`);
          await imageRef.put(imageFile);
          imageUrl = await imageRef.getDownloadURL();
        }
  
        const newEntryRef = firebase.database().ref('toppers').push();
        await newEntryRef.set({
          title: title.value,
          classType: classType.value,
          pdf: pdfUrl,
          image: imageUrl,
        });
  
        // Reset form fields
        title.value = '';
        classType.value = 'classX'; // or classXII
        file.value = '';
        if (image) image.value = '';
        alert('Entry added successfully!');
      } catch (error) {
        console.error("Error adding entry: ", error);
        alert('Failed to add entry.');
      } finally {
        setUploading(false); // Set uploading to false regardless of the outcome
      }
    };

  const handleDeleteEntry = async (entryId, pdfUrl, imageUrl) => {
    try {
      // Delete from database
      await firebase.database().ref(`toppers/${entryId}`).remove(); 

      // Delete PDF
      if (pdfUrl) {
        const pdfRef = firebase.storage().refFromURL(pdfUrl);
        await pdfRef.delete();
      }

      // Optionally delete Image
      if (imageUrl) {
        const imageRef = firebase.storage().refFromURL(imageUrl);
        await imageRef.delete();
      }

      alert('Entry deleted successfully!');
    } catch (error) {
      console.error("Error deleting entry: ", error);
      alert('Failed to delete entry.');
    }
  };

  return (
    <AdminFrame>
      <div className="p-5">
        <form onSubmit={handleSubmit} className="mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <input type="text" name="title" placeholder="Enter title" required className="input"/>
            <select name="classType" className="select">
              <option value="classX">Class X</option>
              <option value="classXII">Class XII</option>
            </select>
            <input type="file" name="image" accept="image/*" className="file-input"/>
            <input type="file" name="file" accept="application/pdf" required className="file-input"/>
          </div>
          <button type="submit" className="submit-btn bg-blue-600 text-white p-2" disabled={uploading}>Add Entry</button>
        </form>
        {uploading && <div className="text-center font-medium text-lg">Uploading data...</div>}
        <table className="min-w-full divide-y divide-gray-200 mt-6">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Title
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Class Type
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                PDF
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Image
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {entries.map((entry) => (
              <tr key={entry.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {entry.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {entry.classType}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <a href={entry.pdf} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-900">
                    View PDF
                  </a>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {entry.image ? <img src={entry.image} alt="Topper" className="h-20 w-auto" /> : 'No Image'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button onClick={() => handleDeleteEntry(entry.id, entry.pdf, entry.image)} className="text-red-600 hover:text-red-900">
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AdminFrame>
  );
};

export default Toppers;
