import React, { useState, useEffect, useCallback } from "react";
import { firebase } from "../db/Firebase";
import AdminFrame from "../components/hoc/AdminFrame";

const ImageModal = ({ imageUrl, onClose }) => {
  if (!imageUrl) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="relative bg-white p-4 max-w-3xl max-h-full overflow-auto">
        <button onClick={onClose} className="absolute top-0 right-0 mt-2 mr-2 text-xl font-bold">
          &times;
        </button>
        <img src={imageUrl} alt="Zoomed In" className="max-w-full max-h-full" />
      </div>
    </div>
  );
};

const Registration = () => {
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [editedData, setEditedData] = useState({});
  const [error, setError] = useState(null);
  const [activeImage, setActiveImage] = useState(null); 

  const handleImageClick = (imageUrl) => {
    setActiveImage(imageUrl);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setActiveImage(null);
  }

  const fetchDataFromFirebase = useCallback(() => {
    try {
      const queryFormsRef = firebase.database().ref("registrationFormData");

      queryFormsRef.on("value", (snapshot) => {
        const queryFormData = snapshot.val();
        if (queryFormData) {
          const dataArray = Object.values(queryFormData);
          setData(dataArray);
          setError(null);
        } else {
          setData([]);
        }
      });
    } catch (error) {
      setError("Error fetching data: " + error.message);
    }
  }, []);

  useEffect(() => {
    fetchDataFromFirebase();
  }, [fetchDataFromFirebase]);

  return (
    <AdminFrame>

      <div className="w-full" >
        <section className="bg-blueGray-50" style={{ overflow: "hidden", zIndex: "-999", position: "absolute" }}>
          <div className="h-16 sticky top-0 bg-emerald-950">
            <h2 className="text-white flex justify-center pt-3 text-3xl font-bold">
              Student Registration Form
            </h2>
          </div>
          <table className="items-center min-w-full" >

            <thead className="sticky top-[60px] bg-slate-200 ">
              <tr className="w-full" >
                <th className=" px-3 py-2 text-[16px] uppercase font-semibold text-left bg-blue-500 text-white" style={{ border: "1px solid gray" }}>
                  SrNo.
                </th>
                <th className=" px-3 py-2 text-[16px] uppercase font-semibold text-left bg-blue-500 text-white" style={{ border: "1px solid gray" }}>
                  Student Name
                </th>
                <th className=" px-3 py-2 text-[16px] uppercase font-semibold text-left bg-blue-500 text-white" style={{ border: "1px solid gray" }}>
                  Father Name
                </th>
                <th className=" px-3 py-2 text-[16px] uppercase font-semibold text-left bg-blue-500 text-white" style={{ border: "1px solid gray" }}>
                  Mother Name
                </th>
                <th className=" px-3 py-2 text-[16px] uppercase font-semibold text-left bg-blue-500 text-white" style={{ border: "1px solid gray" }}>
                  Class
                </th>
                <th className=" px-3 py-2 text-[16px] uppercase font-semibold text-left bg-blue-500 text-white" style={{ border: "1px solid gray" }}>
                  Date of Birth
                </th>
                <th className=" px-3 py-2 text-[16px] uppercase font-semibold text-left bg-blue-500 text-white" style={{ border: "1px solid gray" }}>
                  Gender
                </th>
                <th className=" px-3 py-2 text-[16px] uppercase font-semibold text-left bg-blue-500 text-white" style={{ border: "1px solid gray" }}>
                  Adhar No.
                </th>
                <th className=" px-3 py-2 text-[16px] uppercase font-semibold text-left bg-blue-500 text-white" style={{ border: "1px solid gray" }}>
                  Contact No
                </th>
                <th className=" px-3 py-2 text-[16px] uppercase font-semibold text-left bg-blue-500 text-white" style={{ border: "1px solid gray" }}>
                  Address
                </th>
                <th className=" px-3 py-2 text-[16px] uppercase font-semibold text-left bg-blue-500 text-white" style={{ border: "1px solid gray" }}>
                  Payment Slip
                </th>
              </tr>
            </thead>
            <tbody style={{ border: "1px solid gray" }}>
              {data.map((registrationFormData, index) => (
                <tr key={registrationFormData.id} style={{ border: "1px solid gray" }}>
                  <td className="px-8 text-black align-middle text-[16px]">
                    {index + 1}.
                  </td>
                  <td className="text-black text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.name}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            name: e.target.value,
                          })
                        }
                      />
                    ) : (
                      registrationFormData.name
                    )}
                  </td>
                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.fatherName}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            fatherName: e.target.value,
                          })
                        }
                      />
                    ) : (
                      registrationFormData.fatherName
                    )}
                  </td>
                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.motherName}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            motherName: e.target.value,
                          })
                        }
                      />
                    ) : (
                      registrationFormData.motherName
                    )}
                  </td>
                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.classOption}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            classOption: e.target.value,
                          })
                        }
                      />
                    ) : (
                      registrationFormData.classOption
                    )}
                  </td>
                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.dob}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            dob: e.target.value,
                          })
                        }
                      />
                    ) : (
                      registrationFormData.dob
                    )}
                  </td>
                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.genderOption}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            genderOption: e.target.value,
                          })
                        }
                      />
                    ) : (
                      registrationFormData.genderOption
                    )}
                  </td>
                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.adhar}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            adhar: e.target.value,
                          })
                        }
                      />
                    ) : (
                      registrationFormData.adhar
                    )}
                  </td>
                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.mobileNo}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            mobileNo: e.target.value,
                          })
                        }
                      />
                    ) : (
                      registrationFormData.mobileNo
                    )}
                  </td>
                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.address}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            address: e.target.value,
                          })
                        }
                      />
                    ) : (
                      registrationFormData.address
                    )}
                  </td>

                  <td className="px-8 text-black align-middle text-[16px]" style={{ border: "1px solid gray" }}>
                    {editIndex === index ? (
                      <input
                        type="text"
                        value={editedData.feeSlipImage || ''}
                        onChange={(e) =>
                          setEditedData({
                            ...editedData,
                            feeSlipImage: e.target.value,
                          })
                        }
                      />
                    ) : (
                      registrationFormData.feeSlipImage && (
                        <>
                          <img src={registrationFormData.feeSlipImage} alt="Payment Slip" 
                          onClick={() => handleImageClick(registrationFormData.feeSlipImage)}
                          style={{ width: 'auto', height: '50px' }} />
                        </>
                      )
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        <ImageModal imageUrl={activeImage} onClose={handleCloseModal} />
      </div>

    </AdminFrame>
  );
};

export default Registration;
