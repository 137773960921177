import React, { useState, useEffect } from 'react';
import { firebase, storage } from '../db/Firebase';
import AdminFrame from '../components/hoc/AdminFrame';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const Gallery = () => {
    const [images, setImages] = useState([]);
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    useEffect(() => {
        const fetchImages = async () => {
            const dbRef = firebase.database().ref('gallery');
            dbRef.on('value', (snapshot) => {
                const imgs = snapshot.val();
                const loadedImages = [];
                for (let id in imgs) {
                    loadedImages.push({ id, ...imgs[id] });
                }
                setImages(loadedImages);
            });
        };
        fetchImages();
    }, []);

    const handleChange = (e) => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
            setUploadSuccess(false);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            console.log("No file selected.");
            return;
        }

        setUploading(true);
        const uniqueName = `${new Date().getTime()}-${file.name}`; // Ensure unique file name
        const fileRef = storageRef(storage, `gallery/images/${uniqueName}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Optional: Handle upload progress
            },
            (error) => {
                console.error("Upload error: ", error);
                setUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    const dbRef = firebase.database().ref('gallery');
                    dbRef.push({
                        imageUrl: downloadURL,
                        imageName: uniqueName // Store unique name
                    });
                    setUploading(false);
                    setUploadSuccess(true);
                    setFile(null); // Clear the file state
                    setTimeout(() => setUploadSuccess(false), 3000); // Reset the upload success message
                });
            }
        );
    };

    const deleteImage = (id, imageName) => {
        // Construct the file reference from the unique name instead of a direct URL
        const imageRef = storageRef(storage, `gallery/images/${imageName}`);
        imageRef.delete().then(() => {
            const dbRef = firebase.database().ref('gallery').child(id);
            dbRef.remove();
        }).catch((error) => {
            console.error("Delete error: ", error);
        });
    };


    return (
        <AdminFrame>
            <div className="p-4">
                <input type="file" onChange={handleChange} disabled={uploading} className="mb-4 file:border file:border-blue-500 file:px-4 file:py-2 file:rounded file:text-white file:bg-blue-500 file:cursor-pointer" />
                <button onClick={handleUpload} disabled={uploading || !file} className="mb-4 ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Upload Image
                </button>
                {uploading && <p>Uploading image...</p>}
                {uploadSuccess && <p className="text-green-500 animate-pulse">Image uploaded successfully!</p>}
                <div className="mt-8 overflow-hidden border border-gray-200">
                    <table className="min-w-full leading-normal">
                        <thead>
                            <tr className="border-b">
                                <th className="w-15 px-5 py-3 border-r bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    S.No.
                                </th>
                                <th className="w-60 px-5 py-3 border-r bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    Image
                                </th>
                                <th className="w-25 px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {images.map(({ id, imageUrl, imageName }, index) => (
                                <tr key={id} className="border-b">
                                    <td className="w-16 px-5 py-5 border-r text-sm">
                                        {index + 1}
                                    </td>
                                    <td className="w-96 px-5 py-5 border-r text-sm">
                                        <img src={imageUrl} alt={imageName} className="w-full h-auto" style={{ maxWidth: '700px' }} />
                                    </td>
                                    <td className="w-24 px-5 py-5 text-sm">
                                        <button onClick={() => deleteImage(id, imageName)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </AdminFrame>
    );
};

export default Gallery;
