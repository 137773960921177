import React, { useEffect, useState } from "react";
import AdminFrame from "../components/hoc/AdminFrame";
import {firebase} from "../db/Firebase";

const CampusTour = () => {
  const [campusTour, setCampusTour] = useState([]);

  useEffect(() => {
    const campusTourRef = firebase.database().ref('campusTour');
    campusTourRef.on('value', (snapshot) => {
      const tours = snapshot.val();
      const toursList = [];
      for (let id in tours) {
        toursList.push({ id, ...tours[id] });
      }
      setCampusTour(toursList);
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const title = e.target.elements.title.value; // Only use the title from the form

    try {
      const newTourRef = firebase.database().ref('campusTour').push();
      await newTourRef.set({
        title, // Save only the title
      });

      e.target.elements.title.value = ''; // Reset the title input field

      alert('Tour added successfully!');
    } catch (error) {
      console.error("Error adding tour: ", error);
      alert('Failed to add tour.');
    }
  };

  const handleDeleteTour = async (tourId) => {
    try {
      await firebase.database().ref(`campusTour/${tourId}`).remove();
      alert('Tour deleted successfully!');
    } catch (error) {
      console.error("Error deleting tour: ", error);
      alert('Failed to delete tour.');
    }
  };

  return (
    <AdminFrame>
      <div className="p-5">
        <form onSubmit={handleSubmit} className="mb-6">
          <input
            type="text"
            name="title"
            placeholder="Enter tour title or text"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
          <button
            type="submit"
            className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add Tour
          </button>
        </form>

        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {campusTour.map((tour, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {tour.title}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button onClick={() => handleDeleteTour(tour.id)} className="text-red-600 hover:text-red-900">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AdminFrame>
  );
};

export default CampusTour;
