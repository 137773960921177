import React, { useState } from "react";
import {
  Accordion,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";
import { NavLink, useNavigate } from "react-router-dom";
import { FcBusinessman, FcDebt, FcFinePrint, FcHome } from "react-icons/fc";
import { PowerIcon } from "@heroicons/react/24/solid";
import logo from "../../logo/st.logo.svg";
import { firebase } from "../../db/Firebase";

const AdminFrame = ({ children }) => {
  const [logoutLoading, setLogoutLoading] = useState(false);
  const navigate = useNavigate();

  async function handleLogout() {
    setLogoutLoading(true);
    try {
      await firebase.auth().signOut(); // Use Firebase Auth signOut method
      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      setLogoutLoading(false);
    }
  }

  return (
    <div className="h-screen flex w-[100vw] ">
      <Card
        className="h-[calc(100vh)] w-[15%] fixed left-0 p-2 shadow-xl shadow-blue-gray-900/ bg-cyan-700 
      flex flex-col justify-start "
      >
        <div className="mt-5">
          <img src={logo} alt="brand" className="h-auto w-[250px]" />
        </div>

        <List
          className=" overflow-y-auto"
          style={{
            overflowY: "scroll",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/Home"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcHome className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal">
                  Home
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/school/achievements"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcBusinessman className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal ">
                  Achievements
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/students/Update-campus-tour"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcDebt className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal ">
                  Campus Tour
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/students/Update-circular"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcDebt className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal ">
                  Circular
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/students/Update-calender-event"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcDebt className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal ">
                  Calender Events
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/students/all-calender"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcDebt className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal ">
                  Complete Calender
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/students/Update-event"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcDebt className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal ">
                  Events
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/explore"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcHome className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal">
                  Explore
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/students/enquiry"
                activeClassName="text-blue-500"
                e
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcFinePrint className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal">
                  Enquiry
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/fee"
                activeClassName="text-blue-500"
                e
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcFinePrint className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal">
                  Fee
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/gallery"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcHome className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal">
                  Gallery
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/image-slidder"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcHome className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal">
                  ImageSlidder
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/students/Update-magazine"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcDebt className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal ">
                  Magazine
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/students/registration"
                activeClassName="text-blue-500"
                e
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcFinePrint className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal">
                  Registration
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/popup"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcHome className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal">
                  PopUP
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/students/public-disclosure"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcBusinessman className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal ">
                  Public Disclosure
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <Accordion>
            <ListItem className="p-0 flex items-center hover:bg-red-800 hover:text-white">
              <NavLink
                to="/school/toppers"
                activeClassName="text-blue-500"
                className="border-b-0 p-3 flex items-center"
              >
                <ListItemPrefix>
                  <FcBusinessman className="h-5 w-5 mr-2" />
                </ListItemPrefix>
                <Typography color="white" className="font-normal ">
                  School Toppers
                </Typography>
              </NavLink>
            </ListItem>
          </Accordion>
          <hr className="my-2 border-blue-gray-50" />
          <ListItem
            className=" flex text-red-700  hover:text-white border border-red-800 hover:bg-red-800 
            focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-3 py-2 text-center 
            mr-2 mb-5 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600
             dark:focus:ring-red-900 "
            onClick={() => handleLogout()}
          >
            <ListItemPrefix>
              <PowerIcon className="h-5 w-5 mr-2" />
            </ListItemPrefix>
            Logout
          </ListItem>
        </List>
      </Card>
      <div className="w-[85%] absolute top-0 right-0">{children}</div>
    </div>
  );
};

export default AdminFrame;
