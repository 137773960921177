import React, { useEffect, useState } from 'react';
import AdminFrame from '../components/hoc/AdminFrame';
import { firebase } from '../db/Firebase';

const Calendar = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const eventsRef = firebase.database().ref('calendarEvents');
    eventsRef.on('value', (snapshot) => {
      const events = snapshot.val();
      const eventsList = [];
      for (let id in events) {
        eventsList.push({ id, ...events[id] });
      }
      eventsList.sort((a, b) => b.createdAt - a.createdAt);
      setEvents(eventsList);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { date, title } = e.target.elements;

    try {
      const newEventRef = firebase.database().ref('calendarEvents').push();
      await newEventRef.set({
        date: date.value,
        title: title.value,
        createdAt: new Date().getTime(),
      });

      alert('Event updated successfully!');
      e.target.reset();
    } catch (error) {
      console.error('Error saving event: ', error);
      alert('Failed to save the event.');
    }
  };

  const handleDeleteEvent = async (eventId) => {
    await firebase.database().ref(`calendarEvents/${eventId}`).remove();
    alert('Event deleted successfully.');
  };

  return (
    <AdminFrame>
      <div className="p-5">
        <form onSubmit={handleSubmit} className="mb-6 flex gap-3">
          <input
            type="date"
            name="date"
            required
            className="p-2 border rounded"
          />
          <input
            type="text"
            name="title"
            required
            className="p-2 border rounded"
            placeholder="Event Title"
          />
          <button type="submit" className="p-2 border rounded bg-blue-500 text-white hover:bg-blue-600">
            Add Event
          </button>
        </form>

        <div className="overflow-x-auto">
          <table className="w-full table-auto">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2">Date</th>
                <th className="px-4 py-2">Title</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => (
                <tr key={event.id} className="border-b">
                  <td className="px-4 py-2">{event.date}</td>
                  <td className="px-4 py-2">{event.title}</td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => handleDeleteEvent(event.id)}
                      className="px-4 py-1 text-sm text-white bg-red-500 rounded hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AdminFrame>
  );
};

export default Calendar;
