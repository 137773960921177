import React, { useState, useEffect } from 'react';
import { firebase, storage } from '../db/Firebase';
import AdminFrame from '../components/hoc/AdminFrame';
import {  uploadBytesResumable, getDownloadURL } from "firebase/storage";

const Explore = () => {
    const [images, setImages] = useState([]);
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState(""); // State to hold the title
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    useEffect(() => {
        const fetchImages = async () => {
            const dbRef = firebase.database().ref('explore');
            dbRef.on('value', (snapshot) => {
                const imgs = snapshot.val();
                const loadedImages = [];
                for (let id in imgs) {
                    loadedImages.push({ id, ...imgs[id] });
                }
                setImages(loadedImages);
            });
        };
        fetchImages();
    }, []);

    const handleChange = (e) => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
            setUploadSuccess(false);
        }
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleUpload = () => {
        if (images.length >= 7) {
            alert('Maximum limit of 7 images reached. Cannot upload more images.');
            return; 
        }
        if (!file) {
            console.log("No file selected.");
            return;
        }

        setUploading(true);
        const uniqueName = `${new Date().getTime()}-${file.name}`; 
        const storageRef = storage.ref(`images/${uniqueName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
            },
            (error) => {
                console.error(error);
                setUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    const dbRef = firebase.database().ref('explore');
                    dbRef.push({
                        imageUrl: downloadURL,
                        imageName: uniqueName, 
                        title: title
                    });
                    setUploading(false);
                    setUploadSuccess(true);
                    setFile(null);
                    setTitle(""); 
                    setTimeout(() => setUploadSuccess(false), 3000);
                });
            }
        );
    };

    const deleteImage = (id, imageName) => {
        const imageRef = storage.ref('images').child(imageName);
        imageRef.delete().then(() => {
            const dbRef = firebase.database().ref('explore').child(id);
            dbRef.remove();
        }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <AdminFrame>
            <div className="p-4">
                <input 
                    type="text" 
                    value={title}
                    onChange={handleTitleChange}
                    placeholder="Enter image title" 
                    className="mb-4 w-full p-2 border rounded"
                />
                <input 
                    type="file" 
                    onChange={handleChange} 
                    disabled={uploading} 
                    className="mb-4 file:border file:border-blue-500 file:px-4 file:py-2 file:rounded file:text-white file:bg-blue-500 file:cursor-pointer" 
                />
                <button 
                    onClick={handleUpload} 
                    disabled={uploading || !file || !title} 
                    className="mb-4 ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Upload Image
                </button>
                {uploading && <p>Uploading image...</p>}
                {uploadSuccess && <p className="text-green-500 animate-pulse">Image uploaded successfully!</p>}
                <div className="mt-8 overflow-hidden border border-gray-200">
                    <table className="min-w-full leading-normal">
                        <thead>
                            <tr className="border-b">
                                <th className="px-5 py-3 border-r bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    S.No.
                                </th>
                                <th className="px-5 py-3 border-r bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    Title
                                </th>
                                <th className="px-5 py-3 border-r bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    Image
                                </th>
                                <th className="px-5 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {images.map(({ id, imageUrl, imageName, title }, index) => (
                                <tr key={id} className="border-b">
                                    <td className="px-5 py-5 border-r text-sm">
                                        {index + 1}
                                    </td>
                                    <td className="px-5 py-5 border-r text-sm">
                                        {title}
                                    </td>
                                    <td className="px-5 py-5 border-r text-sm">
                                        <img src={imageUrl} alt={imageName} className="w-full h-auto" style={{ maxWidth: '700px' }} />
                                    </td>
                                    <td className="px-5 py-5 text-sm">
                                        <button onClick={() => deleteImage(id, imageName)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </AdminFrame>
    );
};

export default Explore;
